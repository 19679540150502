.PreviewPost-Paper{
  background-image: linear-gradient(to bottom,  #7d769f, #827e93);
  width: 75%;
  margin: auto;
  height: 450px;
  overflow: hidden;
}

.Post-Container{
  width: 33%;
  margin-bottom: 5%;
  overflow: hidden;
}

.Post-Header-Image{
  overflow: hidden;
  height: 206px;
  margin: 20px;
  display: block;
  text-align: center;
}

.Post-Title {
  margin: 21px;
  padding-bottom: 15px;
  border-bottom: 4px solid #93335f;
  font-size: 18px;
  font-family: 'Bangers', cursive;
  color: white;
}

.Post-Content{
  color: white;
  height: 70px;
  margin: 21px;
  overflow: hidden;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}

.Post-Date {
  font-size: 12px;
  margin: 21px;
  color: #ffffffa1;
}

.PreviewPost-Paper:hover{
  background-image: linear-gradient(to bottom, #6f71a5 , #3d3854);
  cursor: pointer;
}

@media (max-width: 760px) {
  .Post-Container{
    width: 100%;
    margin-bottom: 10%;
  }

  .PreviewPost-Paper{
    width: 100%;
  }
}
