.Header {
  margin: 30px auto 42px auto;
  width: 85%;
  color: white;
  border-bottom: 4px solid #93335f;
}

.Body {
  margin: auto;
  width: 75%;
  height: 75%;
  color: white;
  font-size: 25px;
  font-family: 'Nunito', sans-serif;
}
