.dialog-size {
  background-image: linear-gradient(to bottom, #272842 , #1f1c2d);
  background-color: #272842;
  color: white;
}

.dialog-imageMobile{
  width: 200px;
  height: 200px;
}

.dialog-content{
  margin: 72px;
  font-family: 'Nunito', sans-serif !important;
  font-size: 18px !important;
}


.dialog-image{
  text-align: center;
}

.dialog-title{
  font-size: 35px !important;
}


.dialog-date{
  font-size: 22px;
  margin: 21px;
  color: #ffffffa1;
}

.MuiPaper-root{
  background-color: #1f1c2e !important;
}

@media (max-width: 600px) {
  .dialog-image{
    text-align: center;
    width: 80%;
    overflow: hidden;
    margin: 39px;
  }
}


@media (max-width: 500px) {
  .dialog-image{
    margin: 20px;
  }

  .dialog-content{
    margin: 42px;
    font-family: 'Nunito', sans-serif !important;
    font-size: 18px !important;
  }
}
