.box{
  width:  100%;
}

.box-message{
  width:  100%;
  height: 100px;
}

.box-mobile-name{
  float: right;
  width: 100%;
}
.box-mobile-email{
  float: right;
  width: 100%;
}
.box-mobile-subject{
  width:  100%;
  float: right
}


.box-message-mobile{
  width:  100%;
  height: 50px;
}

.font-mobile{
  font-size: 20px;
}
