.App {
  text-align: center;
  height: 100%;
  margin: 0;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.App-background{
  background-color: red;
  background-image: linear-gradient(to bottom, #0C0A18 , #332F46);
  position: absolute; top: 0; right: 0; bottom: 0; left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.App-Paper{
  height: 80%;
  width: 80%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
  background-color: #2f394e52 !important;
  font: Bangers-Regular;
}
.App-Social{
  text-align: center;
}
.Social-Logo{
    max-width: 35px;
    margin: 20px;
    max-height: 35px;
}
.Social-Facebook{
    max-width: 30px;
    margin: 23px;
    max-height: 30px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  background: #34374e;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #312d43;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0f0d1b;
}

@media (max-width: 760px) {
  /* width */
  ::-webkit-scrollbar {
    display: none;
  }

  .App-background{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

}
