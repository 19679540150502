.dialog-size {
  background-image: linear-gradient(to bottom, #272842 , #1f1c2d);
  background-color: #272842;
  color: white;
}

.dialog-imageMobile{
  width: 200px;
  height: 200px;
}
