.games-size {
  width: 35%;
  margin: auto;
  color: white;
}

.games-cards{
  background-image: linear-gradient(to bottom, #272842 , #1f1c2d);
}
.games-cards:hover{
  background-image: linear-gradient(to bottom, #6f71a5 , #3d3854);
  cursor: pointer;
}

.header-card-content{
  color: white;
}

.games-image{
  width: 320px;
  height: 320px;
}

.games-image-mobile{
  width: 100px;
  height: 100px;
}
.games-image-mobile-baby{
  width: 60px;
  height: 60px;
}
.games-paragraph{
  margin: 5%
}
