.App-AppBar{
  background-color: #34374e !important;
}

.logo-Class{
  max-height: 150px;
  max-width: 125px
}
.MuiMenu-paper{
  background-color: #34374e !important;
}
.NavBar-buttons{
  padding: 32px;
}

.NavBar-buttonsHighlite{
  padding: 32px;
  background-color: #a6a4b0
}
