.main-body{
    margin: 5%;
}

.paper-content{
  margin: 30px;
  font-size: 20px;
  font-family: 'Nunito', sans-serif;
}

.image-body{
  overflow: hidden;
  margin-bottom: -10%;
  width: 100%;
}
.PSB-Image{
  position: relative;
  width: 100%;
  bottom: 100px;
}

.PSB-Paper{
  width: 80%;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
  overflow: overlay;
  position: relative;
  bottom: 110px;
  background-color: #827e93 !important;
  color: white !important;
}

.PSB-MoreInfo{
  margin: auto;
  display: block;
  font-family: 'Bangers', cursive !important;
  background-color: #93335f !important;
  margin: 40px auto !important;
  font-size: 20px !important;
  display: block !important;

}

.Latest-News{
  color: white;
  margin: auto auto 16px auto;
  border-bottom: 3px solid #93335f;
  width: 80%;
}

.Latest-News-Font{
  font-family: 'Nunito', sans-serif !important;
  font-size: 46px !important;
}

.news-container{
  display: flex;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
}

.Button-Container{
  position: absolute;
  margin: 10% 0px 0px 10%;
  z-index: 1;
}

.wishlist-button{
  width: 229px;
}
.Steam-Container{
  display: flex;
  text-align: center;
}

.steam-logo{
  width: 100%;
}

.wishlist-text{
  width: 183%;
}

.steam-button{
  margin: auto;
  display: block;
  font-family: 'Bangers', cursive !important;
  background-color: #93335f !important;
  margin: 40px auto !important;
  font-size: 24px !important;
  display: block !important;
  width: 229px;
}

.loading-circle{
  width: 120px !important;
  height: 120px !important;
}

.loading-flex{
  display: flex;
}

.loading-container{
  margin: 20% auto;
}

@media (max-width: 760px) {
  .PSB-Image{
    bottom: 0px;
  }

  .image-body{
    margin-bottom: -4px;
  }

  .PSB-Paper{
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: auto;
    overflow: overlay;
    position: relative;
    bottom: 0px;
    background-color: #827e93 !important;
  }

  .Latest-News{
    margin: 10% auto 16px auto;
    width: 100%;
  }

  .news-container{
    width: 100%;
  }

}

@media (max-width: 1080px) {
  .Button-Container{
    position: absolute;
    margin: auto;
    width: 45%;
    z-index: 1;
  }

  .steam-button{
    margin: auto;
    display: block;
    font-family: 'Bangers', cursive !important;
    background-color: #93335f !important;
    margin: 40px auto !important;
    font-size: 19px !important;
    display: block !important;
    width: 150px;
  }

}
